import React from "react";

const Emoji = ({ label, char }) => (
  <span role="img" aria-label={label}>
    {char}
  </span>
);

Emoji.AngryFace = () => <Emoji label="Angry Face" char="😡" />;
Emoji.ThumbsDown = () => <Emoji label="Thumbs Down" char="👎🏻" />;
Emoji.Thinking = () => <Emoji label="Thinking" char="🤔" />;
Emoji.Love = () => <Emoji label="Love" char="❤" />;

export default Emoji;
