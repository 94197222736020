import React, { useEffect, useState } from "react";
import AuthComponent from "../auth/Auth";
import firebase from "../../firebase.js";
import NavWrapper from "../nav/NavWrapper";
import AddNames from "./AddNames";
import Vote from "./Vote";
import SignoutButton from "../auth/SignoutButton";
import ShowVotes from "./ShowVotes";
import useLogger from "./userLogger";
import Spinner from "react-bootstrap/Spinner";

const If = NavWrapper.If;

const ifDefined = (value, f) => (value === undefined ? undefined : f(value));
const firestore = firebase.firestore();
const getCurrentUser = () => firebase.auth().currentUser;
const firebaseUserDoc = () => firestore.collection("users").doc(getCurrentUser().uid);
const firebaseDatasetDocument = datasetName => firestore.collection("datasets").doc(datasetName);
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const makeVote = score => ({
  score: score,
  voterName: getCurrentUser().displayName,
  timestamp: Date.now()
});

const Loading = () => (
  <div>
    <Spinner animation="grow" variant="danger" /> <Spinner animation="grow" variant="warning" />{" "}
    <Spinner animation="grow" variant="info" /> <Spinner animation="grow" variant="success" />
  </div>
);

const LoadingWithCaption = ({ caption }) => (
  <div style={{ width: "100%", textAlign: "center", margin: "1rem" }}>
    <Loading />
    <h1>{caption}</h1>
  </div>
);

const LoggedInApp = ({ logOutAction }) => {
  const [datasetHintLoaded, setDatasetHintLoaded] = useState(false);
  const [datasetDoc, setDatasetDoc] = useState(undefined);
  const handleUserDocSnapshot = snapshot => {
    setDatasetHintLoaded(true);
    setDatasetDoc(ifDefined(snapshot.data(), it => firebaseDatasetDocument(it.datasetHint)));
  };
  useEffect(
    () => firebaseUserDoc().onSnapshot(handleUserDocSnapshot),
    [] // Run only on mount
  );
  const logger = useLogger(firebaseUserDoc());

  const [nameDocs, setNameDocs] = useState(undefined);
  const handleNamesCollectionSnapshot = snapshot => setNameDocs(snapshot.docs);
  useEffect(
    () =>
      ifDefined(datasetDoc, it =>
        it.collection("possibleNames").onSnapshot(handleNamesCollectionSnapshot)
      ),
    [datasetDoc] // Run when datasetDoc changes
  );

  const [datasetFields, setDatasetFields] = useState(undefined);
  const handleDatasetFieldsSnapshot = snapshot => setDatasetFields(snapshot.data());
  useEffect(() => {
    return ifDefined(datasetDoc, it => it.onSnapshot(handleDatasetFieldsSnapshot));
  }, [datasetDoc]);

  if (!datasetHintLoaded) {
    return (
      <LoadingWithCaption caption={"Hi " + getCurrentUser().displayName + ". Logging in..."} />
    );
  } else if (datasetDoc === undefined) {
    return <h1>Sorry {getCurrentUser().displayName}. There was an error: no dataset hint</h1>;
  }

  const namesCollection = datasetDoc.collection("possibleNames");
  const recordVote = (nameDoc, score) => {
    logger("Recording score " + score + " for name " + nameDoc.data().name);
    return namesCollection.doc(nameDoc.id).update({
      votes: arrayUnion(makeVote(score))
    });
  };

  if (nameDocs === undefined || datasetFields === undefined) {
    return <LoadingWithCaption caption={"Hi " + getCurrentUser().displayName + ". Loading..."} />;
  } else {
    return (
      <NavWrapper title="Baby Names">
        <If
          tabName="Vote"
          default
          ifSelectedShow={() => (
            <Vote
              surname={datasetFields.surname}
              nameDocs={nameDocs}
              recordVote={recordVote}
              logger={logger}
            />
          )}
        />
        <If
          tabName="Add Names"
          ifSelectedShow={() => <AddNames nameDocs={nameDocs} namesCollection={namesCollection} />}
        />
        <If tabName="See all votes" ifSelectedShow={() => <ShowVotes nameDocs={nameDocs} />} />
        <SignoutButton onClick={logOutAction} />
      </NavWrapper>
    );
  }
};

const App = () => (
  <AuthComponent>
    <LoggedInApp />
  </AuthComponent>
);

export default App;
