import React, { useState, useRef } from "react";
import firebase from "../../firebase";
import VoteButtons from "./VoteButtons";
import findNameDocFactory from "./findNameDocFactory";

const findNameDoc = findNameDocFactory(() => firebase.auth().currentUser.displayName);

const Vote = ({ surname, nameDocs, recordVote, logger, forcedNormalisedId }) => {
  const nameDocsContainer = useRef(null);
  nameDocsContainer.current = nameDocs;
  const [currentNameDocAndStats, setCurrentNameDocAndStats] = useState(() =>
    findNameDoc(nameDocs, logger, forcedNormalisedId)
  );
  const { doc: currentNameDoc, noVoteCount, otherVoteCount } = currentNameDocAndStats;
  const [isRecordingVote, setIsRecordingVote] = useState(false);

  const vote = score => {
    setIsRecordingVote(true);
    recordVote(currentNameDoc, score).then(() => {
      setIsRecordingVote(false);
      setCurrentNameDocAndStats(findNameDoc(nameDocsContainer.current, logger));
    });
  };

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <p style={{ margin: 0, color: "#bbbbbb", fontSize: "0.8rem" }}>
        There are <span style={{ color: "white" }}>{noVoteCount}</span> names that no-one has voted
        on yet, and <span style={{ color: "white" }}>{otherVoteCount}</span> others that you've not seen yet
      </p>
      <h1 style={{ margin: "2rem", fontSize: "3rem", color: "#bbbbbb" }}>
        <span style={{ color: "white" }}>{currentNameDoc.data().name}</span> {surname}
      </h1>
      <VoteButtons vote={vote} isRecordingVote={isRecordingVote} />
    </div>
  );
};

export default Vote;
