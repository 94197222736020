const VoteType = {
  // Names that other people have voted on, that I haven't
  EXCLUSIVE_OTHER: "EXCLUSIVE_OTHER",
  // Names that no-one has voted on
  NEW_VOTE: "NEW_VOTE",
  // Names that I (and possibly others) have voted on before
  MY_VOTE_AGAIN: "MY_VOTE_AGAIN",
  // Vetoed names
  VETOED: "VETOED"
};

const categoriseNames = (me, nameDocs) => {
  const namesIveVotedOn = [];
  const namesSomeoneElseVotedOn = [];
  const namesWithNoVotes = [];
  const vetoedNames = [];

  nameDocs.forEach(nameDoc => {
    const nameObject = nameDoc.data();
    const votes = nameObject.votes === undefined ? [] : nameObject.votes;

    if (votes.length === 0) {
      namesWithNoVotes.push(nameDoc);
    } else if (votes.filter(vote => vote.score === 0).length > 0) {
      vetoedNames.push(nameDoc);
    } else if (votes.filter(vote => vote.voterName === me).length > 0) {
      namesIveVotedOn.push(nameDoc);
    } else {
      namesSomeoneElseVotedOn.push(nameDoc);
    }
  });

  return { namesIveVotedOn, namesSomeoneElseVotedOn, namesWithNoVotes, vetoedNames };
};

const pickType = (typeNum, namesSomeoneElseVotedOn, namesWithNoVotes, namesIveVotedOn) => {
  if (typeNum < 0.3 && namesSomeoneElseVotedOn.length > 0) {
    return VoteType.EXCLUSIVE_OTHER;
  } else if (typeNum < 0.95 && namesWithNoVotes.length > 0) {
    return VoteType.NEW_VOTE;
  } else if (namesIveVotedOn.length > 0) {
    return VoteType.MY_VOTE_AGAIN;
  } else {
    // This should only happen if I've vetoed literally everything in which case WTF?
    return VoteType.VETOED;
  }
};

const randomNameFrom = (names, randomSource) => names[Math.floor(randomSource() * names.length)];

const pickNameOfType = (
  type,
  namesSomeoneElseVotedOn,
  namesWithNoVotes,
  namesIveVotedOn,
  vetoedNames,
  randomSource
) => {
  if (type === VoteType.EXCLUSIVE_OTHER) {
    return randomNameFrom(namesSomeoneElseVotedOn, randomSource);
  } else if (type === VoteType.NEW_VOTE) {
    return randomNameFrom(namesWithNoVotes, randomSource);
  } else if (type === VoteType.MY_VOTE_AGAIN) {
    return randomNameFrom(namesIveVotedOn, randomSource);
  } else if (type === VoteType.VETOED) {
    return randomNameFrom(vetoedNames, randomSource);
  } else {
    throw new Error("Unexpected vote type: " + type);
  }
};

const pickName = (me, nameDocs, typeRandomSource, nameRandomSource, logger, forcedNormalisedId) => {
  const forcedNameObj =
    forcedNormalisedId === undefined
      ? undefined
      : nameDocs.find(nameDoc => nameDoc.data().normalisedId === forcedNormalisedId);
  const {
    namesIveVotedOn,
    namesSomeoneElseVotedOn,
    namesWithNoVotes,
    vetoedNames
  } = categoriseNames(me, nameDocs);
  const typeNum = typeRandomSource();
  const type = pickType(typeNum, namesSomeoneElseVotedOn, namesWithNoVotes, namesIveVotedOn);
  const pickedName =
    forcedNameObj === undefined
      ? pickNameOfType(
          type,
          namesSomeoneElseVotedOn,
          namesWithNoVotes,
          namesIveVotedOn,
          vetoedNames,
          nameRandomSource
        )
      : forcedNameObj;
  logger({
    typeNum: typeNum,
    namesSomeoneElseVotedOn: namesSomeoneElseVotedOn.length,
    namesWithNoVotes: namesWithNoVotes.length,
    namesIveVotedOn: namesIveVotedOn.length,
    vetoedNames: vetoedNames.length,
    chosenName: pickedName.data().name,
    chosenNameType: forcedNameObj === undefined ? type : "FORCED",
    chosenNamePreviousVotes: pickedName.data().votes === undefined ? [] : pickedName.data().votes
  });
  return {
    doc: pickedName,
    noVoteCount: namesWithNoVotes.length,
    otherVoteCount: namesSomeoneElseVotedOn.length
  };
};

const findNameDocFactory = (
  meSource,
  typeRandomSource = Math.random,
  nameRandomSource = Math.random
) => (nameDocs, logger, forcedNormalisedId = undefined) =>
  pickName(meSource(), nameDocs, typeRandomSource, nameRandomSource, logger, forcedNormalisedId);

export default findNameDocFactory;
