import React, { Children, cloneElement, useEffect, useState } from "react";
import firebase from "../../firebase";
import { StyledFirebaseAuth } from "react-firebaseui";

const uiConfig = {
  signInFlow: "popup",
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: { signInSuccessWithAuthResult: () => false }
};

const auth = firebase.auth();

const AuthComponent = props => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(
    () => auth.onAuthStateChanged(user => setIsSignedIn(!!user)),
    [] // Run only on mount
  );

  const logOutAction = () => auth.signOut();
  return isSignedIn ? (
    Children.map(props.children, child => cloneElement(child, { logOutAction }))
  ) : (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
  );
};

export default AuthComponent;
