import { useRef, useState } from "react";

const useLogger = userDoc => {
  const [sessionStartTimestamp] = useState(Date.now().toString());
  const logCollection = userDoc
    .collection("sessions")
    .doc(sessionStartTimestamp)
    .collection("logRecords");
  const logOrderRef = useRef(0);
  return payload => {
    console.log(payload);
    const order = logOrderRef.current;
    logOrderRef.current++;
    logCollection.doc(order.toString()).set({
      payload,
      timestamp: Date.now()
    });
  };
};

export default useLogger;
