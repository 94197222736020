import React from "react";
import Button from "react-bootstrap/Button";
import Emoji from "./Emoji";

const VoteButton = ({ variant, score, children, isRecordingVote, vote }) => (
  <Button
    style={{ width: "100%", marginTop: "0.5rem", fontSize: "2rem" }}
    variant={isRecordingVote ? "outline-secondary" : variant}
    disabled={isRecordingVote}
    onClick={() => vote(score)}
  >
    {children}
  </Button>
);

const VoteButtons = ({ vote, isRecordingVote }) => {
  return (
    <div>
      <VoteButton variant={"danger"} score={0} isRecordingVote={isRecordingVote} vote={vote}>
        No, veto <Emoji.AngryFace />
      </VoteButton>
      <VoteButton variant={"warning"} score={1} isRecordingVote={isRecordingVote} vote={vote}>
        I don't think so <Emoji.ThumbsDown />
      </VoteButton>
      <VoteButton variant={"info"} score={2} isRecordingVote={isRecordingVote} vote={vote}>
        Maybe <Emoji.Thinking />
      </VoteButton>
      <VoteButton variant={"success"} score={3} isRecordingVote={isRecordingVote} vote={vote}>
        I <Emoji.Love /> it
      </VoteButton>
    </div>
  );
};

export default VoteButtons;
