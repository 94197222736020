import React, { useState } from "react";
import Badge from "react-bootstrap/Badge";
import Emoji from "./Emoji";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const initials = voterName =>
  voterName
    .split(" ")
    .map(namePart => namePart.substring(0, 1))
    .join("");

const ShowVotes = ({ nameDocs, setSelectedContext }) => {
  const namesWithVotes = nameDocs
    .filter(nameDoc => !!nameDoc.data().votes && nameDoc.data().votes.length > 0)
    .map(nameDoc => nameDoc.data());

  const vetoed = [];
  const unVetoed = [];
  namesWithVotes.forEach(nameObject => {
    if (nameObject.votes.filter(vote => vote.score === 0).length > 0) {
      vetoed.push(nameObject);
    } else {
      unVetoed.push(nameObject);
    }
  });

  const unVetoedNamesWithStats = unVetoed.map(name => {
    const votes = name.votes;
    const meanScore = votes.reduce((sum, vote) => sum + vote.score, 0) / votes.length;
    const groupedVotes = votes.reduce((obj, vote) => {
      const voterInitials = initials(vote.voterName);
      if (obj[voterInitials] === undefined) {
        obj[voterInitials] = [];
      }
      obj[voterInitials].push(vote);
      return obj;
    }, {});
    // Each users' max score for this name
    const maxScores = Object.keys(groupedVotes).map(voterInitials =>
      Math.max(...groupedVotes[voterInitials].map(vote => vote.score))
    );
    const minOfMaxScores = Math.min(...maxScores);
    return {
      ...name,
      meanScore,
      groupedVotes,
      minOfMaxScores
    };
  });
  const sortedUnvetoed = unVetoedNamesWithStats.sort((name1, name2) => {
    const minComp = name2.minOfMaxScores - name1.minOfMaxScores;
    if (minComp !== 0) {
      return minComp;
    }
    return name2.meanScore - name1.meanScore;
  });

  const vetoedNamesWithStats = vetoed.map(name => {
    const votes = name.votes;
    const maxScore = Math.max(...votes.map(vote => vote.score));
    const groupedVotes = votes.reduce((obj, vote) => {
      const voterInitials = initials(vote.voterName);
      if (obj[voterInitials] === undefined) {
        obj[voterInitials] = [];
      }
      obj[voterInitials].push(vote);
      return obj;
    }, {});
    return {
      ...name,
      maxScore,
      groupedVotes
    };
  });
  const sortedVetoed = vetoedNamesWithStats.sort((name1, name2) => name2.maxScore - name1.maxScore);

  return (
    <ShowVotesContent
      sortedUnvetoed={sortedUnvetoed}
      sortedVetoed={sortedVetoed}
      setSelectedContext={setSelectedContext}
    />
  );
};

const minOfMaxScoresVariantFunction = o => o.minOfMaxScores;
const maxScoreVariantFunction = o => o.maxScore;

const ShowVotesContent = ({ sortedUnvetoed, sortedVetoed, setSelectedContext }) => {
  const [modalName, setModelName] = useState(null);

  return (
    <div>
      <Tabs defaultActiveKey="unvetoed">
        <Tab eventKey="unvetoed" title="Unvetoed">
          {sortedUnvetoed.map(nameObject => (
            <MemoisedName
              key={nameObject.normalisedId}
              nameObject={nameObject}
              variantFunction={minOfMaxScoresVariantFunction}
              setModelName={setModelName}
            />
          ))}
        </Tab>
        <Tab eventKey="vetoed" title="Vetoed">
          {sortedVetoed.map(nameObject => (
            <MemoisedName
              key={nameObject.normalisedId}
              nameObject={nameObject}
              variantFunction={maxScoreVariantFunction}
              setModelName={setModelName}
            />
          ))}
        </Tab>
      </Tabs>
      <Modal
        show={modalName !== null}
        onHide={() => setModelName(null)}
        animation={false}
        style={{ color: "black" }}
      >
        {modalName !== null ? (
          <>
            <ModalContent
              nameObject={modalName}
              closeAction={() => setModelName(null)}
              setSelectedContext={setSelectedContext}
            />
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModelName(null)}>
                Close
              </Button>
            </Modal.Footer>
          </>
        ) : null}
      </Modal>
    </div>
  );
};

const ModalContent = ({ nameObject, setSelectedContext }) => {
  const votes = nameObject.votes;
  const votesWithoutTimestamps = votes.filter(vote => vote.timestamp === undefined);
  const votesWithTimestamps = votes
    .filter(vote => vote.timestamp !== undefined)
    .sort((name1, name2) => name1.timestamp - name2.timestamp);
  const sortedVotes = [...votesWithoutTimestamps, ...votesWithTimestamps];
  const formatDate = vote => new Date(vote.timestamp).toISOString().split("T")[0];

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>{nameObject.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sortedVotes.map((vote, index) =>
          vote.timestamp === undefined ? (
            <p key={index}>
              {vote.voterName}: <VoteEmoji score={vote.score} />
            </p>
          ) : (
            <p key={index}>
              {vote.voterName}: <VoteEmoji score={vote.score} /> ({formatDate(vote)})
            </p>
          )
        )}
        <Button
          onClick={() =>
            setSelectedContext({
              tabName: "Vote",
              extraProps: { forcedNormalisedId: nameObject.normalisedId }
            })
          }
        >
          Vote on this name
        </Button>
      </Modal.Body>
    </div>
  );
};

const VoteEmoji = ({ score }) => {
  switch (score) {
    case 3:
      return <Emoji.Love />;
    case 2:
      return <Emoji.Thinking />;
    case 1:
      return <Emoji.ThumbsDown />;
    default:
      return <Emoji.AngryFace />;
  }
};

const Name = ({ nameObject, variantFunction, setModelName }) => {
  const variant = ["danger", "warning", "info", "success"][variantFunction(nameObject)];
  return (
    <Badge
      variant={variant}
      style={{ margin: "0.1rem", fontSize: "1rem" }}
      onClick={() => setModelName(nameObject)}
    >
      <strong>{nameObject.name}</strong>
    </Badge>
  );
};
const MemoisedName = React.memo(Name);

export default ShowVotes;
