import React, { useState } from "react";
import firebase from "../../firebase";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

const AddNames = ({ nameDocs, namesCollection }) => {
  const existingNormalisedIds = nameDocs.map(doc => doc.id);

  const [textContent, setTextContent] = useState("");
  const [updating, setUpdating] = useState(false);
  const splitAndTrimmedNames = textContent
    .split(/[^A-Za-z]+/)
    .map(name => name.trim())
    .filter(name => name.length > 0);
  const nameObjects = splitAndTrimmedNames.map(name => {
    const normalisedId = name.toLowerCase();
    return { normalisedId: normalisedId, name: name };
  });
  const distinctNameObjects = nameObjects.filter(
    (value, index, array) =>
      array.findIndex(obj => obj.normalisedId === value.normalisedId) === index
  );
  const nameObjectsToAdd = distinctNameObjects.filter(
    nameObject => !existingNormalisedIds.includes(nameObject.normalisedId)
  );

  const saveNames = () => {
    const batch = firebase.firestore().batch();
    nameObjectsToAdd.forEach(nameObjectToAdd =>
      batch.set(namesCollection.doc(nameObjectToAdd.normalisedId), nameObjectToAdd)
    );
    setUpdating(true);
    batch.commit().then(() => {
      setUpdating(false);
      setTextContent("");
    });
  };

  return (
    <div>
      <h1>Add some more names to the database:</h1>
      <Form>
        <Form.Group controlId="namesInput">
          <Form.Label>Type names here:</Form.Label>
          <Form.Control
            as="textarea"
            rows="5"
            value={textContent}
            onChange={event => setTextContent(event.target.value)}
          />
        </Form.Group>
      </Form>
      <Button variant="success" onClick={() => saveNames()}>
        {updating ? "(Saving)" : "Add these (" + nameObjectsToAdd.length + ")"}
      </Button>
      <div>
        {distinctNameObjects.map(nameObject => {
          const normalisedId = nameObject.normalisedId;
          const nameAlreadyExists = existingNormalisedIds.includes(normalisedId);
          return (
            <Badge
              id={normalisedId}
              variant={nameAlreadyExists ? "secondary" : "success"}
              style={{ margin: "2px", fontSize: "120%" }}
            >
              {nameObject.name}
            </Badge>
          );
        })}
      </div>
    </div>
  );
};

export default AddNames;
