import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCUUfFQlwizThnIYHalVJeL6YM1Dwd86ps",
  authDomain: "babynames-a1d58.firebaseapp.com",
  databaseURL: "https://babynames-a1d58.firebaseio.com",
  projectId: "babynames-a1d58",
  storageBucket: "",
  messagingSenderId: "1017941391684",
  appId: "1:1017941391684:web:3063e58cf243e9c1a0ff11"
};
firebase.initializeApp(config);

export default firebase;
